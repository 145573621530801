export default {
  VIEW_ACCOUNT: 'view_account',
  ADD_PRIVATE_CAMPAIGN_FLAG: 'add_private_campaign_flag',
  CHANGE_PRIVATE_CAMPAIGN_FLAG: 'change_private_campaign_flag',
  ADD_AGENCY: 'add_agency',
  CHANGE_AGENCY: 'change_agency',
  DELETE_AGENCY: 'delete_agency',
  VIEW_AGENCY: 'view_agency',
  ADD_ROLE: 'add_role',
  CHANGE_ROLE: 'change_role',
  DELETE_ROLE: 'delete_role',
  VIEW_ROLE: 'view_role',
  ADD_USER: 'add_user',
  CHANGE_USER: 'change_user',
  DELETE_USER: 'delete_user',
  IMPORT_USERS: 'import_users',
  VIEW_PROFILE: 'view_profile',
  VIEW_USER: 'view_user',
  ADD_ASSIGNMENT: 'add_assignment',
  CHANGE_ASSIGNMENT: 'change_assignment',
  DELETE_ASSIGNMENT: 'delete_assignment',
  VIEW_ASSIGNMENT: 'view_assignment',
  ADD_CAMPAIGN: 'add_campaign',
  VIEW_AUDIT_LOG: 'view_auditlog',
  CHANGE_CAMPAIGN: 'change_campaign',
  DELETE_CAMPAIGN: 'delete_campaign',
  VIEW_CAMPAIGN: 'view_campaign',
  ADD_VISIT: 'add_visit',
  ALLOW_ANY_VISIT: 'allow_any_visit',
  CHANGE_VISIT: 'change_visit',
  DELETE_VISIT: 'delete_visit',
  IMPORT_VISIT: 'import_visit',
  VIEW_VISIT: 'view_visit',
  VIEW_ENFORCEMENT_VISITS: 'view_enforcement_visits',
  VIEW_EDUCATION_VISITS: 'view_education_visits',
  VIEW_ASSESSMENT_VISITS: 'view_assessment_visits',
  VIEW_MAPS: 'view_maps',
  ADD_VENUE: 'add_venue',
  CHANGE_VENUE: 'change_venue',
  DELETE_VENUE: 'delete_venue',
  VIEW_VENUE: 'view_venue',
  ADD_RETAILER: 'add_retailer',
  CHANGE_RETAILER: 'change_retailer',
  DELETE_RETAILER: 'delete_retailer',
  IMPORT_RETAILER: 'import_retailer',
  IMPORT_RETAILERS: 'import_retailers',
  VIEW_SIMILARSTORES: 'view_similarstores',
  VIEW_SIMILARSTORES_MATCHING_RULE: 'view_similarstores_matching_rule',
  MERGE_RETAILER: 'merge_retailer',
  VIEW_RETAILER: 'view_retailer',
  VIEW_RETAILER_SIMILAR_STORES: 'view_retailer_similar_stores',
  ADD_FORM: 'add_form',
  CHANGE_FORM: 'change_form',
  DELETE_FORM: 'delete_form',
  VIEW_FORM: 'view_form',
  VIEW_YOUTH: 'view_youth',
  ADD_YOUTH: 'add_youth',
  CAN_ACCESS_STAFF_SUPERUSER_INFO: 'can_access_staff_superuser_info',
  VIEW_REVISIT_TABLE: 'view_revisit_table',
  VIEW_USER_FILTER: 'view_user_filter',
  MA_VIEW_OTHER_VIOLATION_INFO: 'ma_view_other_violation_info',
  VIEW_OTHER_VIOLATION_INFO: 'view_other_violation_info',
  CHANGE_YOUTH: 'change_youth',
  DELETE_YOUTH: 'delete_youth',
  IMPORT_YOUTH: 'import_youth',
  VIEW_FORM_YOUTH_DROPDOWN: 'view_form_youth_dropdown',
  VIEW_YOUTH_LINK: 'view_youth_link',
  VIEW_REPORTS: 'view_reports',
  VIEW_SNAPSHOT_MAPS: 'view_snapshot_maps',
  ADD_VISIT_VIOLATION_INFO: 'add_visitviolationinfo',
  CHANGE_VISIT_VIOLATION_INFO: 'change_visitviolationinfo',
  DELETE_VISIT_VIOLATION_INFO: 'delete_visitviolationinfo',
  VIEW_VISIT_VIOLATION_INFO: 'view_visitviolationinfo',
  CAN_SEND_PASSWORD_RESET_EMAIL: 'can_send_password_reset_email',
  DISPARATE_BURDEN_VIEW: 'view_infographic_disparateburden',
  VIEW_ENFOCEMENT_OVERVIEW: 'view_infographic_enforcementoverview',
  VIEW_POST_LANDSCAPE: 'view_infographic_poslandscape',
  VIEW_RETAILER_REDUCTION: 'view_infographic_retailerreduction',
  VIEW_YOUTH_APPEAL: 'view_infographic_youthappeal',
  VIEW_SUMMARY_REPORT_AREA: 'view_report_area_summary_report',
  VIEW_REPORT_ENFORCEMENT_OVERVIEW: 'view_report_enforcement_overview_report',
  VIEW_REPORT_EDUCATION_OVERVIEW: 'view_report_education_overview_report',
  VIEW_SNAPSHOT_DENSITY: 'view_snapshot_map_density',
  VIEW_SNAPSHOT_POLICY: 'view_snapshot_map_policy',
  VIEW_SNAPSHOT_SCHOOLS: 'view_snapshot_map_schools',
  VIEW_SNAPSHOT_AVAILABILITY: 'view_snapshot_map_availability',
  VIEW_SNAPSHOT_PROMOTION: 'view_snapshot_map_promotion',
  VIEW_SNAPSHOT_PRICE: 'view_snapshot_map_price',
  VIEW_SNAPSHOT_EDUCATION: 'view_snapshot_map_education',
  VIEW_SNAPSHOT_ENFORCEMENT: 'view_snapshot_map_enforcement',
  VIEW_SNAPSHOTS: 'views_snapshots',
  VIEW_STORIES: 'view_stories',
};
