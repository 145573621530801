import React, { useReducer, useCallback, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { isEmpty, debounce } from 'lodash';
import { useWindowDimensions } from 'hooks';
import { PageContainer } from 'modules';
import BackPageButton from 'modules/BackPageButton';
import { useDispatch, useSelector } from 'react-redux';
import { RetailerEntity, AuthEntity } from '_entities';
import { Button, Input, BaseSelect, LoadedSelect, Picker, RadioButtonGroup, Section, Loader } from 'ui-kit';
import { notification } from 'utils/services';
import PropTypes from 'prop-types';
import { BUTTON_TYPES, URLS, MESSAGES } from '_constants';
import SingleRetailerMap from '../../old/RetailerView/components/MainInfo/singleRetailerMap';
import {
  formNames,
  radioButtonDefaultValues,
  textInputs,
  radioButtonsGroup,
  formInitialState,
  FORM_ACTION_TYPES,
  retailerTypeOptions,
  ageRestrictedFacilityOptions,
  PAGE_COLUMNS,
} from './constants';
import { formReducer, init, setFormForEdit, getRadioValue } from './_utils';
import * as Styled from './styles';

const { fetchCityList, addRetailer, editRetailer, checkNewRetailerLocation } = RetailerEntity.actions;
const { getRetailerInfo: getRetailerData, getCityList } = RetailerEntity.selectors;
const { getUserState } = AuthEntity.selectors;

const AddRetailers = ({ isEditPage }) => {
  const [isActiveUpdateLocation, setIsActiveUpdateLocation] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const retailerInfo = useSelector(getRetailerData);
  const userStateAddress = useSelector(getUserState);
  const cityList = useSelector(getCityList);
  const [state, formDispatch] = useReducer(formReducer, formInitialState, init);
  const { width: viewportWidth } = useWindowDimensions();
  const formStateData = state.data.attributes;
  const formStateErrors = state.errors;
  const citySelectRef = useRef();
  const retailerAttributes = retailerInfo?.attributes;
  const isHorizontalLayout = viewportWidth >= 1124;
  const loadSelectTextType = isEditPage ? 'editCity' : 'addCity';

  const handleSubmit = async (e) => {
    e.preventDefault();

    formDispatch({ type: FORM_ACTION_TYPES.VALIDATE_DATA });

    if (!state.canBeSubmitted) return;
    setLoading(true);

    const retailerData = Object.keys(formStateData).reduce((acc, item) => {
      switch (item) {
        case formNames.type: {
          if (isEmpty(formStateData[item].value)) {
            acc[item] = formStateData[item].value || null;
          }
          return acc;
        }
        case formNames.ageRestriction: {
          if (isEmpty(formStateData[item].value)) {
            acc[item] = formStateData[item].value;
          }
          return acc;
        }

        case formNames.city: {
          acc[item] = formStateData[item].value;
          return acc;
        }

        case formNames.closeDate: {
          if (formStateData[formNames.inBusiness]) {
            acc[item] = null;
          } else {
            const rawData = new Date(formStateData[item].selected);
            const result = rawData.toLocaleDateString('sv-SE');
            acc[item] = result;
          }
          return acc;
        }

        case formNames.sellTobacco: {
          if (isEditPage) return acc;
          acc[item] = formStateData[item];
          return acc;
        }
        case formNames.chain:
        case formNames.sellAlcohol:
        case formNames.hasPharmacy:
        case formNames.hasAVC:
        case formNames.acceptsWIC:
        case formNames.acceptsSNAP:
        case formNames.internetSales: {
          acc[item] = formStateData[item];
          return acc;
        }
        case formNames.sellCigars:
        case formNames.sellCigarettes:
        case formNames.sellEcigarettes:
        case formNames.sellSmokeless: {
          acc[item] = formStateData[item];
          return acc;
        }

        case formNames.latlong: {
          if (formStateData[item].latitude && formStateData[item].longitude) {
            acc[item] = formStateData[item];
          }
          return acc;
        }

        case formNames.address:
        case formNames.address2: {
          if (retailerAttributes && formStateData[item] !== retailerAttributes[item]) {
            acc.addr_updated = true;
          }
          acc[item] = formStateData[item];
          return acc;
        }

        case formNames.other:
        case formNames.other2:
        case formNames.phone: {
          acc[item] = formStateData[item] || null;
          return acc;
        }

        default: {
          acc[item] = formStateData[item];
          return acc;
        }
      }
    }, {});

    const updatedRetailer = { data: { ...state.data, attributes: { ...retailerData } } };
    if (isEditPage) {
      updatedRetailer.data.id = retailerInfo.id;
    }
    try {
      if (isEditPage) {
        await dispatch(editRetailer(retailerInfo.id, updatedRetailer));
        notification.success(MESSAGES.RETAILER.UPDATE.SUCCESS);
        history.push(`${URLS.retailers}/${retailerInfo.id}`);
      } else {
        const {
          data: { id },
        } = await dispatch(addRetailer(updatedRetailer));
        notification.success(MESSAGES.RETAILER.CREATE.SUCCESS);
        history.push(`${URLS.retailers}/${id}`);
      }
    } catch (err) {
      const errors = err.response?.data?.errors;
      if (Array.isArray(errors)) {
        notification.error(err.response?.data?.errors[0].detail);
      } else {
        notification.error(MESSAGES.COMMON_ERROR);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleInBusinessSwitcher = useCallback(({ target: { name, value } }) => {
    formDispatch({ type: FORM_ACTION_TYPES.ENTER_DATA, name, payload: value });
    if (value) {
      formDispatch({
        type: FORM_ACTION_TYPES.ENTER_DATA,
        name: formNames.closeDate,
        payload: { value: '', selected: '' },
      });
    }
  }, []);

  const handleChange = useCallback(({ target: { name, value } }) => {
    formDispatch({
      type: FORM_ACTION_TYPES.ENTER_DATA,
      name,
      payload: value,
      initAttributes: retailerAttributes,
    });
  }, []);

  const handleSelectChange = useCallback((obj) => {
    formDispatch({ type: FORM_ACTION_TYPES.ENTER_DATA, name: obj.name, payload: obj });
  }, []);

  const handleCityChange = useCallback((obj) => {
    let result = obj;
    if (!result) {
      result = {
        value: '',
        label: '',
        name: formNames.city,
      };
    }
    handleSelectChange(result);
  }, []);

  const handlePickerChange = useCallback((date) => {
    const closeDay = new Date(date);
    const value = closeDay.toLocaleDateString('es-PA');

    formDispatch({
      type: FORM_ACTION_TYPES.ENTER_DATA,
      name: formNames.closeDate,
      payload: { value, selected: closeDay },
    });
  }, []);

  const cityOptions = useCallback((arr) => {
    let selectOptions = [];
    if (arr.length) {
      selectOptions = arr.map((item) => {
        return {
          value: item[0],
          label: item[0],
          name: formNames.city,
        };
      });
    }
    return selectOptions;
  }, []);

  const pageTitle = isEditPage ? 'Edit retailer' : 'Add retailer';
  const submitButtonTitle = isEditPage ? 'Save changes' : 'Confirm';

  const handleUpdateLocation = useCallback(() => {
    setIsActiveUpdateLocation((prev) => !prev);
  }, []);

  const onChangeLocation = (newlatlong) => {
    // GIN MAP
    retailerInfo.attributes.latlong.latitude = newlatlong.lat;
    retailerInfo.attributes.latlong.longitude = newlatlong.lng;
  };

  /**
   * @param value = {latitude: latitudeValue, longitude: longitudeValue}
   *
   */
  const handleConfirmUpdatedLocation = useCallback(() => {
    setIsActiveUpdateLocation((prev) => !prev);
    // GIN MAP
    if (!isEditPage) return false;

    const getNotificationMessage = (secGeo, account) => {
      if (!secGeo && !account) {
        return MESSAGES.UPDATE_LOCATION.FAIL.BOTH;
      }
      if (!secGeo) {
        return MESSAGES.UPDATE_LOCATION.FAIL.GEOGRAPHY;
      }
      if (!account) {
        return MESSAGES.UPDATE_LOCATION.FAIL.ACCOUNT;
      }
      return MESSAGES.UPDATE_LOCATION.SUCCESS;
    };

    return (async () => {
      const newLatLong = retailerInfo.attributes.latlong; // see onChangeLocation()
      const params = `latitude=${newLatLong.latitude}&longitude=${newLatLong.longitude}`;
      try {
        const { isWithinAccountBoundaries, isWithinSecondaryGeographies } = await dispatch(
          checkNewRetailerLocation(params),
        );
        const message = getNotificationMessage(isWithinSecondaryGeographies, isWithinAccountBoundaries);
        if (!isWithinSecondaryGeographies && !isWithinAccountBoundaries) {
          return notification.error(message);
        }
        notification.success(MESSAGES.UPDATE_LOCATION.SUCCESS);
        formDispatch({ type: FORM_ACTION_TYPES.ENTER_DATA, name: 'loc_updated', payload: true });
        return formDispatch({ type: FORM_ACTION_TYPES.ENTER_DATA, name: formNames.latlong, payload: newLatLong });
      } catch (err) {
        console.log(err);
        return notification.error(MESSAGES.COMMON_ERROR);
      }
    })();
  }, [dispatch, isEditPage]);

  const handleCancelUpdateLocation = useCallback(() => {
    setIsActiveUpdateLocation((prev) => !prev);
  }, []);

  const handleLoadOptions = useCallback((inputValue, callback) => {
    const newData = { ...formStateData[formNames.city], value: inputValue, label: inputValue };
    formDispatch({ type: FORM_ACTION_TYPES.ENTER_DATA, name: formNames.city, payload: newData });
    const fetchCities = debounce(() => {
      try {
        dispatch(fetchCityList(inputValue)).then((arr) => {
          callback(cityOptions(arr));
        });
      } catch (err) {
        console.log(err);
      }
    }, 300);

    if (inputValue.length > 2) {
      fetchCities();
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(retailerAttributes)) {
      const newFormData = {
        attributes: { ...setFormForEdit(retailerInfo.attributes) },
        relationships: { ...retailerInfo.relationships },
      };
      formDispatch({ type: FORM_ACTION_TYPES.SET_DATA, payload: newFormData });
    }
  }, [retailerInfo]);

  useEffect(() => {
    if (!isEditPage) {
      formDispatch({ type: FORM_ACTION_TYPES.RESET_DATA, payload: formInitialState.data.attributes });
    }
  }, []);

  const latitude = isEditPage ? formStateData[formNames.latlong]?.latitude : 'none';
  const longitude = isEditPage ? formStateData[formNames.latlong]?.longitude : 'none';
  const stateAddress = isEditPage ? formStateData[formNames.state] : userStateAddress;
  formStateData[formNames.state] = stateAddress;

  const formatDateForCalendar = (date) => {
    if (date) return new Date(date);
    if (retailerAttributes?.closed_date && !formStateData[formNames.inBusiness])
      return new Date(retailerAttributes?.closed_date);
    return null;
  };

  // work around bug: in add mode, retailerInfo has info from previous Retailer incl latlong
  const retailerDataForMap = isEditPage ? retailerInfo : { id: 0, attributes: formStateData };

  if (loading) return <Loader />;

  return (
    <PageContainer>
      <Styled.MainContainer>
        <Styled.TopLine>
          <Styled.TitleWrapper>
            <BackPageButton />
            <Styled.Title>{pageTitle}</Styled.Title>
            <Styled.Info>* - required fields</Styled.Info>
          </Styled.TitleWrapper>
          <Styled.ButtonWrapper>
            <Button onClick={handleSubmit} variant={BUTTON_TYPES.DANGER} text={submitButtonTitle} />
          </Styled.ButtonWrapper>
        </Styled.TopLine>
        <Styled.Inner>
          <Styled.SectionGroup>
            <Section title="Retailer information" isHorizontalLayout={isHorizontalLayout} pageColumns={3}>
              <Styled.InputWrapper>
                <Input
                  name={formNames.name}
                  title="Name"
                  onChange={handleChange}
                  error={formStateErrors[formNames.name]}
                  value={formStateData[formNames.name]}
                  required
                  floatingLabel
                  pageColumns={PAGE_COLUMNS}
                />
                <Styled.HorizontalBorder />
              </Styled.InputWrapper>
              <Styled.InputWrapper>
                <Input
                  name={formNames.address}
                  title="Address"
                  onChange={handleChange}
                  error={formStateErrors[formNames.address]}
                  value={formStateData[formNames.address]}
                  required
                  floatingLabel
                  pageColumns={PAGE_COLUMNS}
                />
                <Styled.HorizontalBorder />
              </Styled.InputWrapper>
              <Styled.InputWrapper>
                <Input
                  name={formNames.address2}
                  title="Address 2"
                  onChange={handleChange}
                  value={formStateData[formNames.address2]}
                  floatingLabel
                  pageColumns={PAGE_COLUMNS}
                />
                <Styled.HorizontalBorder />
              </Styled.InputWrapper>
              <Styled.InputWrapper>
                <LoadedSelect
                  loadOptions={handleLoadOptions}
                  labelText="City"
                  value={formStateData[formNames.city]}
                  onChange={handleCityChange}
                  inputId={formNames.city}
                  error={formStateErrors[formNames.city]}
                  className={formStateErrors[formNames.city] ? 'error' : ''}
                  isClearable
                  ref={citySelectRef}
                  required
                  pageColumns={PAGE_COLUMNS}
                  loadSelectTextType={loadSelectTextType}
                  optionsArray={cityList}
                />
                <Styled.HorizontalBorder />
              </Styled.InputWrapper>
              <Styled.InputWrapper>
                <Styled.Location isHorizontalLayout>
                  <Styled.LocationLabel id="state">State</Styled.LocationLabel>
                  <Styled.LocationText aria-labelledby="state">{stateAddress}</Styled.LocationText>
                </Styled.Location>
                <Styled.HorizontalBorder />
              </Styled.InputWrapper>
              <Styled.InputWrapper>
                <Input
                  name={formNames.zipCode}
                  title="Zip Code"
                  onChange={handleChange}
                  value={formStateData[formNames.zipCode]}
                  error={formStateErrors[formNames.zipCode]}
                  required
                  floatingLabel
                  pageColumns={PAGE_COLUMNS}
                />
                <Styled.HorizontalBorder />
              </Styled.InputWrapper>
              <Styled.InputWrapper>
                <Styled.Location isHorizontalLayout>
                  <Styled.LocationLabel id="state">County</Styled.LocationLabel>
                  <Styled.LocationText aria-labelledby="state">{formStateData[formNames.county]}</Styled.LocationText>
                </Styled.Location>
                <Styled.HorizontalBorder />
              </Styled.InputWrapper>
              <Styled.InputWrapper>
                <Input
                  name={formNames.phone}
                  title="Phone number"
                  onChange={handleChange}
                  value={formStateData[formNames.phone]}
                  floatingLabel
                  pageColumns={PAGE_COLUMNS}
                />
              </Styled.InputWrapper>
            </Section>
            <Section title="Additional information" pageColumns={3} isHorizontalLayout={isHorizontalLayout}>
              <Styled.InputWrapper>
                <BaseSelect
                  name={formNames.type}
                  labelText="Store type"
                  options={retailerTypeOptions}
                  value={formStateData[formNames.type]}
                  onChange={handleSelectChange}
                  inputId={formNames.type}
                  placeholder="Select an option"
                  pageColumns={PAGE_COLUMNS}
                />
                <Styled.HorizontalBorder />
              </Styled.InputWrapper>
              <Styled.InputWrapper>
                <RadioButtonGroup
                  name={formNames.chain}
                  title="Chain"
                  options={radioButtonDefaultValues}
                  direction="column"
                  value={getRadioValue(formStateData[formNames.chain])}
                  onChange={handleChange}
                  pageColumns={PAGE_COLUMNS}
                />
                <Styled.HorizontalBorder />
              </Styled.InputWrapper>
              {textInputs.map(({ id, name, title }) => (
                <Styled.InputWrapper key={id}>
                  <Input
                    name={name}
                    title={title}
                    floatingLabel
                    value={formStateData[name]}
                    onChange={handleChange}
                    pageColumns={PAGE_COLUMNS}
                  />
                  <Styled.HorizontalBorder />
                </Styled.InputWrapper>
              ))}
              <Styled.InputWrapper>
                <RadioButtonGroup
                  name={formNames.inBusiness}
                  title="In business"
                  options={radioButtonDefaultValues.slice(1)}
                  direction="column"
                  value={formStateData[formNames.inBusiness]}
                  onChange={handleInBusinessSwitcher}
                  pageColumns={PAGE_COLUMNS}
                />
                <Styled.HorizontalBorder />
              </Styled.InputWrapper>
              {!formStateData[formNames.inBusiness] && (
                <Styled.InputWrapper>
                  <Picker
                    label="Closed date (mm/dd/yyyy)"
                    isActive={formStateData[formNames.inBusiness] !== false}
                    showMonthYearDropdown
                    idInput="closeDay"
                    onChange={handlePickerChange}
                    selected={formatDateForCalendar(formStateData[formNames.closeDate].selected)}
                    inputFor="closeDate"
                    isFullWidth
                    pageColumns={PAGE_COLUMNS}
                  />
                  <Styled.HorizontalBorder />
                </Styled.InputWrapper>
              )}
              <Styled.InputWrapper>
                <BaseSelect
                  name={formNames.ageRestriction}
                  labelText="Age restricted facility"
                  options={ageRestrictedFacilityOptions}
                  value={formStateData[formNames.ageRestriction]}
                  onChange={handleSelectChange}
                  inputId={formNames.ageRestriction}
                  placeholder="Select an option"
                  pageColumns={PAGE_COLUMNS}
                />
                <Styled.HorizontalBorder />
              </Styled.InputWrapper>
              {radioButtonsGroup.map(({ title, name, isSubcategory, disabled }) => (
                <Styled.InputWrapper key={title} isSubcategory={isSubcategory}>
                  <RadioButtonGroup
                    title={title}
                    name={name}
                    options={radioButtonDefaultValues}
                    direction="column"
                    value={getRadioValue(formStateData[name])}
                    onChange={handleChange}
                    disabled={disabled}
                    pageColumns={PAGE_COLUMNS}
                  />
                  <Styled.HorizontalBorder />
                </Styled.InputWrapper>
              ))}
              <Styled.InputWrapper>
                <Input
                  name={formNames.url}
                  title="URL"
                  value={formStateData[formNames.url]}
                  onChange={handleChange}
                  floatingLabel
                  pageColumns={PAGE_COLUMNS}
                />
              </Styled.InputWrapper>
            </Section>
          </Styled.SectionGroup>
          <Section title="Location">
            <Styled.MapWrapper>
              <SingleRetailerMap
                isDraggable={isActiveUpdateLocation}
                retailerDotTitle="Retailer location"
                retailerData={retailerDataForMap}
                onChangeLocation={onChangeLocation}
              />
            </Styled.MapWrapper>
            <Styled.GisInfoWrapper>
              <Styled.GisInfo>Latitude: {latitude}</Styled.GisInfo>
              <Styled.GisInfo>Longitude: {longitude}</Styled.GisInfo>
            </Styled.GisInfoWrapper>
            {isEditPage && (
              <Styled.EditWrapper>
                <Styled.EditDescription>
                  To manually update the location, click Update Location below, click on the point on the map and drag
                  it to the desired location. Then click Save new location and Save changes.
                </Styled.EditDescription>

                {!isActiveUpdateLocation && (
                  <Button variant={BUTTON_TYPES.DARK} onClick={handleUpdateLocation} text="Update location" />
                )}

                {isActiveUpdateLocation && (
                  <Styled.UpdateLocationButtonWrapper>
                    <Button
                      variant={BUTTON_TYPES.DANGER}
                      onClick={handleConfirmUpdatedLocation}
                      text="Save new location"
                    />
                    <Button variant={BUTTON_TYPES.DARK} onClick={handleCancelUpdateLocation} text="Cancel" />
                  </Styled.UpdateLocationButtonWrapper>
                )}
              </Styled.EditWrapper>
            )}
          </Section>
        </Styled.Inner>
      </Styled.MainContainer>
    </PageContainer>
  );
};

AddRetailers.propTypes = {
  isEditPage: PropTypes.bool,
};

AddRetailers.defaultProps = {
  isEditPage: false,
};

export default AddRetailers;
